<template>
	<modal class="NoxModalTopPartners" name="NoxModalTopPartners" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'editSprintSettings'">Настройки спринта</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'editSprintSettings'">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Статус:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxStatus)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange"></toggle-button>
								<div v-html="noxAlertStatus"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Дата начала:</div>
							<div class="nox_modal_info_row_value">
								<datetime type="date" class="nox_datetime" format="dd.MM.yyyy" :phrases="{ ok: '[Выбрать]', cancel: '[Закрыть]' }" v-model="noxSprintStart" placeholder="дата начала спринта" auto></datetime>
								<div v-html="noxAlertSprintStart"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Дата окончания:</div>
							<div class="nox_modal_info_row_value">
								<datetime type="date" class="nox_datetime" format="dd.MM.yyyy" :phrases="{ ok: '[Выбрать]', cancel: '[Закрыть]' }" v-model="noxSprintFinish" placeholder="дата окончания спринта" auto></datetime>
								<div v-html="noxAlertSprintFinish"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'editSprintSettings'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertStatus: '',
			noxAlertSprintStart: '',
			noxAlertSprintFinish: '',
			noxType: '',
			noxStatus: 0,
			noxSprintStart: '',
			noxSprintFinish: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxType = event.params.type;
				this.noxStatus = 0;
				this.noxSprintStart = '';
				this.noxSprintFinish = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editSprintSettings') {

					this.noxStatus = this.$parent.noxSprintSettings.status;
					this.noxSprintStart = this.$parent.noxSprintSettings.sprint_start;
					this.noxSprintFinish = this.$parent.noxSprintSettings.sprint_finish;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertStatus = '';
				this.noxAlertSprintStart = '';
				this.noxAlertSprintFinish = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id спринта.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id спринта.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать статус спринта.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный статус спринта.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо выбрать дату начала спринта.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо выбрать верную дату начала спринта.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо выбрать дату окончания спринта.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо выбрать верную дату окончания спринта.'; }
				else if (i == 9) { this.noxTemp = 'Id спринта не найден в системе.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'editSprintSettings') {
						if (!_this.noxSprintStart) {
							_this.noxAlertSprintStart = _this.getError(5);
						}
						else if ((new Date(_this.noxSprintStart)) == 'Invalid Date') {
							_this.noxAlertSprintStart = _this.getError(6);
						}
						if (!_this.noxSprintFinish) {
							_this.noxAlertSprintFinish = _this.getError(7);
						}
						else if ((new Date(_this.noxSprintFinish)) == 'Invalid Date') {
							_this.noxAlertSprintFinish = _this.getError(8);
						}
						else if ((new Date(_this.noxSprintFinish)) < (new Date(_this.noxSprintStart))) {
							_this.noxAlertSprintFinish = _this.getError(8);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/sprint/settings';
							config.data = { id: 1, status: _this.noxStatus, sprint_start: _this.noxSprintStart, sprint_finish: _this.noxSprintFinish.replace(/00:00:00/gi, '23:59:59') };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'editSprintSettings') {
									_this.$parent.noxSprintSettings.status = _this.noxStatus;
									_this.$parent.noxSprintSettings.sprint_start = _this.noxSprintStart;
									_this.$parent.noxSprintSettings.sprint_finish = _this.noxSprintFinish;
									_this.$parent.initSelectOptions();
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxType == 'editSprintSettings') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertStatus = _this.getError(3); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertStatus = _this.getError(4); }
										else if (data.response.data.error == 'SPRINT_START_IS_EMPTY') { _this.noxAlertSprintStart = _this.getError(5); }
										else if (data.response.data.error == 'SPRINT_START_NOT_VALID') { _this.noxAlertSprintStart = _this.getError(6); }
										else if (data.response.data.error == 'SPRINT_FINISH_IS_EMPTY') { _this.noxAlertSprintFinish = _this.getError(7); }
										else if (data.response.data.error == 'SPRINT_FINISH_NOT_VALID') { _this.noxAlertSprintFinish = _this.getError(8); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxType == 'editSprintSettings') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(9); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(event) {
				this.noxStatus = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalTopPartners');
			}
		}
	}
</script>
